@use '../base' as *;

// --------------------------------

// (START) Global editor code https://codyhouse.co/ds/globals/colors

// --------------------------------

:root, [data-theme="default"] {
  --main-color: #ae9c5d; 
  // main
  @include defineColorHSL(--color-primary-darker, 250, 84%, 38%);
  @include defineColorHSL(--color-primary-dark, 250, 84%, 46%);
  @include defineColorHSL(--color-primary, 250, 84%, 54%);
  @include defineColorHSL(--color-primary-light, 250, 84%, 60%);
  @include defineColorHSL(--color-primary-lighter, 250, 84%, 67%);

  @include defineColorHSL(--color-accent-darker, 342, 89%, 38%);
  @include defineColorHSL(--color-accent-dark, 342, 89%, 43%);
  @include defineColorHSL(--color-accent, 342, 89%, 48%);
  @include defineColorHSL(--color-accent-light, 342, 89%, 56%);
  @include defineColorHSL(--color-accent-lighter, 342, 89%, 62%);

  @include defineColorHSL(--color-black, 204, 28%, 7%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 47%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 50%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 80%);

  @include defineColorHSL(--color-success-darker, 122, 50%, 47%);
  @include defineColorHSL(--color-success-dark, 122, 50%, 52%);
  @include defineColorHSL(--color-success, 122, 50%, 60%);
  @include defineColorHSL(--color-success-light, 122, 50%, 69%);
  @include defineColorHSL(--color-success-lighter, 122, 50%, 76%);

  @include defineColorHSL(--color-error-darker, 342, 89%, 38%);
  @include defineColorHSL(--color-error-dark, 342, 89%, 43%);
  @include defineColorHSL(--color-error, 342, 89%, 48%);
  @include defineColorHSL(--color-error-light, 342, 89%, 56%);
  @include defineColorHSL(--color-error-lighter, 342, 89%, 62%);

  // background
  @include defineColorHSL(--color-bg-darker, 210, 4%, 89%);
  @include defineColorHSL(--color-bg-dark, 180, 3%, 94%);
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-bg-light, 180, 3%, 100%);
  @include defineColorHSL(--color-bg-lighter, 210, 4%, 100%);

  // color contrasts
  @include defineColorHSL(--color-contrast-lower, 180, 1%, 84%);
  @include defineColorHSL(--color-contrast-low, 210, 2%, 64%);
  @include defineColorHSL(--color-contrast-medium, 204, 2%, 46%);
  @include defineColorHSL(--color-contrast-high, 210, 7%, 21%);
  @include defineColorHSL(--color-contrast-higher, 204, 28%, 7%);
}

[data-theme="dark"] {
  // main
  @include defineColorHSL(--color-primary-darker, 250, 93%, 57%);
  @include defineColorHSL(--color-primary-dark, 250, 93%, 61%);
  @include defineColorHSL(--color-primary, 250, 93%, 65%);
  @include defineColorHSL(--color-primary-light, 250, 93%, 69%);
  @include defineColorHSL(--color-primary-lighter, 250, 93%, 72%);

  @include defineColorHSL(--color-accent-darker, 342, 92%, 41%);
  @include defineColorHSL(--color-accent-dark, 342, 92%, 47%);
  @include defineColorHSL(--color-accent, 342, 92%, 54%);
  @include defineColorHSL(--color-accent-light, 342, 92%, 60%);
  @include defineColorHSL(--color-accent-lighter, 342, 92%, 65%);

  @include defineColorHSL(--color-black, 204, 28%, 7%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 46, 100%, 47%);
  @include defineColorHSL(--color-warning-dark, 46, 100%, 50%);
  @include defineColorHSL(--color-warning, 46, 100%, 61%);
  @include defineColorHSL(--color-warning-light, 46, 100%, 71%);
  @include defineColorHSL(--color-warning-lighter, 46, 100%, 80%);

  @include defineColorHSL(--color-success-darker, 122, 50%, 47%);
  @include defineColorHSL(--color-success-dark, 122, 50%, 52%);
  @include defineColorHSL(--color-success, 122, 50%, 60%);
  @include defineColorHSL(--color-success-light, 122, 50%, 69%);
  @include defineColorHSL(--color-success-lighter, 122, 50%, 76%);

  @include defineColorHSL(--color-error-darker, 342, 92%, 41%);
  @include defineColorHSL(--color-error-dark, 342, 92%, 47%);
  @include defineColorHSL(--color-error, 342, 92%, 54%);
  @include defineColorHSL(--color-error-light, 342, 92%, 60%);
  @include defineColorHSL(--color-error-lighter, 342, 92%, 65%);

  // background
  @include defineColorHSL(--color-bg-darker, 204, 15%, 6%);
  @include defineColorHSL(--color-bg-dark, 203, 18%, 9%);
  @include defineColorHSL(--color-bg, 203, 24%, 13%);
  @include defineColorHSL(--color-bg-light, 203, 18%, 17%);
  @include defineColorHSL(--color-bg-lighter, 204, 15%, 20%);

  // color contrasts
  @include defineColorHSL(--color-contrast-lower, 208, 12%, 24%);
  @include defineColorHSL(--color-contrast-low, 208, 6%, 40%);
  @include defineColorHSL(--color-contrast-medium, 213, 5%, 56%);
  @include defineColorHSL(--color-contrast-high, 223, 8%, 82%);
  @include defineColorHSL(--color-contrast-higher, 240, 100%, 99%);

  // font rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// --------------------------------

// (END) Global editor code

// --------------------------------
body {
  background-color: #000;
}

*::-moz-selection { /* Code for Firefox */
  display: none;
}

*::selection {
  display: none;
}

* {
  scrollbar-width: none;          /* "auto" or "thin" */
  scrollbar-color: var(--main-color) #333;   /* scroll thumb and track */
}

*::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-thumb {
  display: none;
}

*::-webkit-scrollbar-corner {
  display: none;
}