@use '../base' as *;
.header {
  background-color: transparent;
  padding: var(--space-xs) 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: var(--space-md) var(--space-md);
  &.active {
    position: fixed;
  }
  .quick-menu {
    position: fixed;
    max-width: 400px;
    width: 100%;
    z-index: 4000;
    height: 100%;
    top: 0;
    left: -100%;
    background-color: #000;
    transition: left 0.5s ease-in-out;
    padding: 200px var(--space-md) var(--space-md) var(--space-md);
    .quick-menu-box {
      color: var(--main-color);
      .quick-i {
        li {
          font-size: 16px;
          margin-right: var(--space-xs);
        }
      }
      h3 {
        font-size: 16px;
        font-weight: bold;
        color: var(--main-color);
        margin-bottom: var(--space-sm);
        margin-bottom: 0px;
      }
      p {
        font-size: 16px;
        margin-bottom: var(--space-sm);
        em {
          font-style: italic;
        }
        span {
          display: block;
        }
      }
    }
    &.active {
      left: 0%;
    }
  }
  .menu-box {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 9999;
    .menuButton {
      cursor: pointer;
      i {
        font-size: 35px;
        color: var(--main-color);
      }
    }
  }
  .main-logo img {
    max-width: 270px;
    width: 100%;
  }
  .nav-list {
    li {
      display: inline-flex;
      align-items: center;
      &:first-child {
        a {
          font-weight: bold;
        }
      }
    }
    a {
      color: var(--main-color);
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
    .bx {
      font-size: 30px;
    }
  }
}

.footer {
  background-color: #000;
  padding: var(--space-xs) 0;
  width: 100%;
  .main-logo {
    max-width: 270px;
    width: 100%;
  }
 
  .nav-list {
    width: 100%;
    justify-content: center;
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      &:first-child {
        a {
          font-weight: bold;
        }
      }
    }
    a {
      color: var(--main-color);
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
    .bx {
      font-size: 30px;
    }
    &.quick-i {
      li {
        color: #fff;
      }
    }
  }
}
