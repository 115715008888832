@use '../base' as *;
.grid-full {
  width: 100%;
  .grid-full-primary {
    cursor: pointer;
  }
  @include breakpoint(md) {
    .grid-full-primary {
      height: 60vh;
      width: 100%;
 
      position: relative;
      
    }
    
  }
 
}

.full-ratio {
  width: auto; 
  height: 100%; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  flex-wrap: wrap;


  .react-player {
    padding-top: 56.25%;
    position: relative; 
    cursor: pointer;
    &:not(:only-child) {
      margin-bottom: 50px;
    }
  }
  &.single {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    &.active {
      opacity: 1;
    }
    
  }
  .react-player > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.grid-full-gallery {
  display: flex;
  flex-wrap: wrap;
  .box {
      position: relative;
      width: 100%;
      cursor: pointer;
     
      @include breakpoint(md) {
        width: 33.3%;
      }
    
      .full-ratio {
        opacity: 0;
        transition: opacity 0.03s linear;
      }
      &:nth-child(5n+2) {
        width: 100%;
        @include breakpoint(md) {
          width: 50%;
        }
      }
      &:nth-child(5n+3) {
        width: 100%;
        @include breakpoint(md) {
          width: 50%;
        }
      }
      &:first-child {
        width: 100%;
        height: 60vh;
        .full-ratio {
          .react-player {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .bk-quick {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        opacity: 1;
        transition: opacity 0.2s linear;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
      }
     

      &:hover {
        .bk-quick {
          opacity: 0;
        }
        .full-ratio {
          opacity: 1;
        }
      }
  }
  
}

.quick-info {
  position: absolute;
  z-index: 100;
  bottom: 20px;
  left: 20px;
  color: #fff;
  h2 {
    font-size: var(--text-sm);
    font-weight: bold;
    color: #fff;
  }
  p {
    font-size: var(--text-sm);
  }
}

.MuiBox-root {
  outline: none!important;
}

.modal {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  background-color: rgba(0,0,0, 0.77);
  outline: none;
  border: none;
  .container {
    padding: 60px 25px 40px 25px;
  }
  .close-btn {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
    @include breakpoint(md) {
      position: fixed;
      top: 20px;
      right: 50px;
    }
    svg {
      fill: var(--main-color);
      @include breakpoint(md) {
        width: 54px!important;
        height: 54px!important;
      }
    }
  }
}

.video-detail {
  margin-top: 30px;
  h2 {
    font-weight: bold;
  }
  * {
    color: var(--main-color);
  }
}

.gallery {
  img:not(:last-child) {
    margin-bottom: 10px;
  }
  img {
    width: 100%;
  }
}