@use 'base' with (
  $breakpoints: (
    'xs': 32rem, // ~512px
    'sm': 48rem, // ~768px
    'md': 64rem, // ~1024px
    'lg': 80rem, // ~1280px
    'xl': 90rem  // ~1440px
  ),
  $grid-columns: 12
);


/*! purgecss start ignore */
@use 'custom-style';
@use 'components';
/*! purgecss end ignore */
