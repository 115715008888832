@use '../base' as *;

:root {
  // radius
  --radius: 0.25em; // border radius base size
  // 👇 uncomment to modify default radius values
  // --radius-sm: calc(var(--radius)/2);
  // --radius-md: var(--radius);
  // --radius-lg: calc(var(--radius)*2);

  // box shadow - 👇 uncomment to modify default shadow values
  // --shadow-xs:  0 0.1px 0.3px rgba(0, 0, 0, 0.06),
  //               0 1px 2px rgba(0, 0, 0, 0.12);
  // --shadow-sm:  0 0.3px 0.4px rgba(0, 0, 0, 0.025),
  //               0 0.9px 1.5px rgba(0, 0, 0, 0.05), 
  //               0 3.5px 6px rgba(0, 0, 0, 0.1);
  // --shadow-md:  0 0.9px 1.5px rgba(0, 0, 0, 0.03), 
  //               0 3.1px 5.5px rgba(0, 0, 0, 0.08), 
  //               0 14px 25px rgba(0, 0, 0, 0.12);
  // --shadow-lg:  0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014), 
  //               0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038), 
  //               0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085), 
  //               0 30px 42px -1px rgba(0, 0, 0, 0.15);
  // --shadow-xl:  0 1.5px 2.1px -6px rgba(0, 0, 0, 0.012), 
  //               0 3.6px 5.2px -6px rgba(0, 0, 0, 0.035), 
  //               0 7.3px 10.6px -6px rgba(0, 0, 0, 0.07), 
  //               0 16.2px 21.9px -6px rgba(0, 0, 0, 0.117), 
  //               0 46px 60px -6px rgba(0, 0, 0, 0.2);
}

// --------------------------------

// (START) Global editor code https://codyhouse.co/ds/globals/shared-styles

// --------------------------------

.hover\:reduce-opacity {
  opacity: 1;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.hover\:scale {
  transition: transform 0.3s var(--ease-out-back);

  &:hover {
    transform: scale(1.1);
  }
}

.hover\:elevate {
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

// text styles
.link-subtle {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    color: var(--color-primary);
  }
}

// --------------------------------

// (END) Global editor code

// --------------------------------