@use '../base' as *;

// --------------------------------

// (START) Global editor code https://codyhouse.co/ds/globals/forms

// --------------------------------

:root {
  --form-control-font-size: 1em;
  --form-control-padding-x: var(--space-xs);
  --form-control-padding-y: var(--space-xxs);
  --form-control-radius: var(--radius-md);
}

.form-control {
  background: var(--color-bg-dark);
  line-height: 1.2;
  transition: all 0.2s ease;

  &::placeholder {
    opacity: 1;  
    color: var(--color-contrast-low);
  }

  &:focus {
    background: var(--color-bg);
    box-shadow: 0px 0px 0px 2px var(--color-primary), var(--shadow-sm);
    outline: none;
  }
}

.form-control--disabled, .form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"], .form-control.form-control--error {
  box-shadow: 0px 0px 0px 2px var(--color-error);

  &:focus {
    box-shadow: 0px 0px 0px 2px var(--color-error), var(--shadow-sm);
  }
}

.form-legend {
  font-size: var(--text-md);
}

.form-label {
  font-size: var(--text-sm);
}

// --------------------------------

// (END) Global editor code

// --------------------------------